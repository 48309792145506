import css from 'styled-jsx/css';

export const styles = css`
    .button {
        @apply relative cursor-pointer px-[1.2rem] py-[.6rem];
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.2s ease;
    }

    .button.fluid-width {
        @apply w-full;
    }

    .button.default-height {
        min-height: 34px;
    }

    .button.fluid-height {
        @apply h-full;
    }

    .button.auto-height {
        height: auto;
    }

    .button.disabled {
        cursor: default;
        opacity: 0.45;
        pointer-events: none;
    }

    .button.outlined {
        border-width: 1px;
    }

    .button.contained.primary {
        @apply text-white;
    }

    .button.contained.secondary {
        @apply text-base;
    }

    .button.contained.secondary:hover {
        @apply text-black;
    }

    .button.contained.merigold {
        @apply text-white;
        background-color: var(--color-merigold);
    }

    .button.contained.merigold:hover {
        opacity: 0.7;
    }

    .button.outlined.primary {
        @apply border-black;
    }

    .button.outlined.primary:hover {
        @apply border-white;
    }

    .button.outlined:hover,
    .button.outlined:focus {
        outline: 0;
    }
`;

import cx from 'classnames';
import Link from 'next/link';
import noLinkHash from '@/utils/noLinkHash';

import Background from '../Background';
import TextContainer from '../Background/TextContainer';
import Container from '../Container';
import Media from '../Media';
import SubCTA from '../SubCTA';
import LinkContainer from '../SubCTA/LinkContainer';
import SpacingLayout from '../SpacingLayout';
import SubHeader from '../SubHeader';
import ContentReplace from '../../utils/contentReplace';
import IDonateForm from '@/components/IDonateForm';

import { geoData } from '@/utils/geoData';
// import { Heading } from '../WYSIWYG';
import { getFlexibleColumnWidth } from '../../utils/style';
import { getMediaResourceProps } from '../../utils/media';

const ColumnsContent = props => {
    const { columns, columnWrapperCssClassCssSafelist, classesForEveryColumnCssSafelist, subBackground, subHeader, subLayout, subcta } = props;
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
    const { defaultSpacing, layoutCssSafelist, contentWidth, customWidth } = subLayout;
    const geo = geoData(columnWrapperCssClassCssSafelist);
    let columnRowClass;

    if (geo !== 'CN' && columnWrapperCssClassCssSafelist?.includes('in-canada')) return null;
    if (geo !== 'UK' && columnWrapperCssClassCssSafelist?.includes('in-uk')) return null;

    return (
        <Background {...subBackground}>
            <Container width={contentWidth} customWidth={customWidth}>
                <SpacingLayout contentWidth={contentWidth} defaultSpacing={defaultSpacing} className={layoutCssSafelist}>
                    <SubHeader {...subHeader} />

                    <div className={cx('column-container', columnRowClass, columnWrapperCssClassCssSafelist)}>
                        {columns?.map((column: any, index: number) => {
                            if (!column) {
                                return null;
                            }
                            if (column.lineBreak) {
                                return <div key={index} className="w-full" />;
                            }

                            const resourceProps = getMediaResourceProps(column?.subMedia);
                            const noMedia = resourceProps.type === 'Image' && !resourceProps.image;
                            const { subHeader, subContent, subBackground, subMedia, columnCssClassCssSafelist, subcta, formOptions } = column;
                            const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta || [];
                            const { backgroundImage, textContainerClassesCssSafelist } = subBackground || [];
                            const imageLink = subMedia?.imageLink?.url;
                            const columnGeo = geoData(columnCssClassCssSafelist);

                            const designationId = formOptions?.designationId || 'd4c15680-8d55-4d98-b538-1aa1e1e57ef2';
                            const campaignId = formOptions?.campaignId || '9f092f15-ba22-4fdf-90c7-996c4b97f9d4';
                            const allocation = formOptions?.allocationId || '16040';
                            const formId = formOptions?.formId || '38638';
                            const confirmationRedirectUrl = formOptions?.confirmationRedirectUrl || 'https://jewsforjesus.org/thank-you';

                            if (columnGeo !== 'CN' && columnCssClassCssSafelist?.includes('in-canada')) return null;
                            if (columnGeo !== 'UK' && columnCssClassCssSafelist?.includes('in-uk')) return null;

                            return (
                                <div
                                    className={cx(
                                        'relative w-full',
                                        'sub-column-container',
                                        subHeader?.sectionCssClassCssSafelist,
                                        columnCssClassCssSafelist,
                                        classesForEveryColumnCssSafelist,
                                        getFlexibleColumnWidth(column?.columnWidth || 'span-6', column.customColumnWidth || false)
                                    )}
                                    key={`column-content-${index}`}>
                                    <Background {...subBackground}>
                                        {!noMedia && (
                                            <div className={subMedia?.mediaContainerCssCssSafelist}>
                                                {imageLink ? (
                                                    <Link
                                                        hrefLang="en"
                                                        aria-label={resourceProps?.image?.altText || 'Link'}
                                                        target={imageLink && !imageLink.startsWith('/') ? '_blank' : '_self'}
                                                        title={resourceProps?.image?.altText || 'Link'}
                                                        className="flex"
                                                        href={imageLink}
                                                        scroll={noLinkHash(imageLink)}>
                                                        <Media {...resourceProps} className={cx('mx-auto object-cover', subMedia?.makeImageRound && 'rounded-full')} />
                                                    </Link>
                                                ) : (
                                                    <Media {...resourceProps} className={cx('mx-auto object-cover', subMedia?.makeImageRound && 'rounded-full')} />
                                                )}
                                            </div>
                                        )}
                                        <TextContainer classes={textContainerClassesCssSafelist} bgImage={backgroundImage}>
                                            <SubHeader {...subHeader} />
                                            {/* 
                                            {column?.content && (
                                                <Heading
                                                    html={column?.content}
                                                    as="div"
                                                    style={{
                                                        color: column?.textColor || 'inherit'
                                                    }}
                                                    {...(column?.title && {
                                                        className: 'mt-3'
                                                    })}
                                                />
                                            )} */}

                                            {formOptions && (
                                                <div
                                                    className={cx([
                                                        formOptions?.boxShadow && 'px-4 pb-6 shadow-md',
                                                        'idonate-form-container',
                                                        formOptions?.idonateClassesCssSafelist
                                                    ])}>
                                                    <IDonateForm
                                                        donationModule
                                                        idonate={false}
                                                        formId={formId}
                                                        allocationId={allocation}
                                                        redirect={formOptions?.confirmationRedirectUrl}
                                                        campaignId={campaignId}
                                                        designationId={designationId}
                                                        embedId={formOptions?.embedId?.trim()}
                                                    />
                                                </div>
                                            )}

                                            {subContent && (
                                                <div className={`content-container ${column?.contentCssClassesCssSafelist}`}>
                                                    <ContentReplace content={subContent} />
                                                </div>
                                            )}

                                            {callToAction?.length > 0 && (
                                                <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                                                    {callToAction?.map((singleCta, index) => {
                                                        return (
                                                            <SubCTA
                                                                key={index}
                                                                type={singleCta?.ctaDisplay}
                                                                href={singleCta?.ctaLink?.url}
                                                                target={singleCta?.ctaTarget}
                                                                linkTarget={singleCta?.ctaLink?.target}
                                                                text={singleCta?.ctaLink?.title}
                                                                bgColor={singleCta?.ctaBgColor}
                                                                arrowSize="large"
                                                                textColor={singleCta?.ctaTextColor || 'white'}
                                                                className={`${singleCta?.ctaCssClassesCssSafelist}`}
                                                                modalContents={singleCta?.modalContents}
                                                                darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== 'yellow')}
                                                            />
                                                        );
                                                    })}
                                                </LinkContainer>
                                            )}
                                        </TextContainer>
                                    </Background>
                                </div>
                            );
                        })}
                    </div>
                    {callToAction?.length > 0 && (
                        <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                            {callToAction?.map((singleCta, index) => {
                                return (
                                    <div key={index} className={cx(singleCta?.ctaCssClassesCssSafelist)}>
                                        <SubCTA
                                            type={singleCta?.ctaDisplay}
                                            href={singleCta?.ctaLink?.url}
                                            target={singleCta?.ctaTarget}
                                            linkTarget={singleCta?.ctaLink?.target}
                                            text={singleCta?.ctaLink?.title}
                                            bgColor={singleCta?.ctaBgColor}
                                            arrowSize="large"
                                            textColor={singleCta?.ctaTextColor || 'white'}
                                            className={singleCta?.ctaCssClassesCssSafelist}
                                            modalContents={singleCta?.modalContents}
                                            darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== '#ffcc43')}
                                        />
                                    </div>
                                );
                            })}
                        </LinkContainer>
                    )}
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default ColumnsContent;

export const contentAlignment = (alignment) => {
    switch (alignment) {
        case 'center':
            return 'text-center';
        case 'right':
            return 'md:text-right';
        case 'left':
            return 'md:text-left';
    }
};

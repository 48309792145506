import * as React from 'react';
import Link from 'next/link';
import cx from 'classnames';
import PageModal from '../PageModal';
import Button from '../Button';
import { Heading } from '../WYSIWYG';
import noLinkHash from '@/utils/noLinkHash';
// import { SubCTAProps } from './utils';

const SubCTA = props => {
    const { type, href, textColor, bgColor, text, size, linkTarget, target, className, arrowSize, textProps, textClassName, modalContents, darkStyles } = props;
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const textStyle = textColor ? { color: textColor } : {};
    const isModal = target === 'Modal';
    const modal = getModal();

    let buttonHoverClasses = '';
    switch (bgColor) {
        case '#1295d8':
            buttonHoverClasses = 'hover:bg-[#43b4ee]';
            break;
        case '#ffffff':
            buttonHoverClasses = 'hover:bg-yellow';
            break;
        case '#11375d':
            buttonHoverClasses = 'hover:bg-lightBlue';
            break;
        case '#000000':
            buttonHoverClasses = 'hover:bg-[#555]';
            break;
        case '#ffcc43':
            buttonHoverClasses = 'hover:bg-[#ffba00]';
            break;
    }

    function getModal() {
        if (!isModal || !href) return null;
        const pathname = href !== '#' && new URL(href).pathname.slice(1);
        const correctedPathname = `/${pathname}`;
        return <PageModal open={modalIsOpen} onClose={() => setModalIsOpen(false)} targetUri={correctedPathname} modalContents={modalContents} />;
    }
    const Text = () => {
        // const _size = type === 'button' ? 'h6' : size;
        return (
            <Heading
                html={text}
                // color="inherit"
                as={size || 'p'}
                className={cx(textClassName, 'mb-0', 'font-bold')}
                {...textProps}
            />
        );
    };
    if (!href || !text) return null;
    switch (type) {
        case 'arrow':
            return (
                <>
                    <Link
                        {...(target && { target })}
                        style={{ ...textStyle }}
                        className={cx('translate-arrow-on-hover inline-flex items-center hover:underline', className)}
                        href={href}
                        passHref
                        scroll={noLinkHash(href)}>
                        <Text />
                        {arrowSize === 'large' ? (
                            <svg className="arrow-svg ml-6" width="79" height="24" viewBox="0 0 79 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M78.0607 13.0607C78.6464 12.4749 78.6464 11.5251 78.0607 10.9393L68.5147 1.3934C67.9289 0.807611 66.9792 0.807611 66.3934 1.3934C65.8076 1.97919 65.8076 2.92893 66.3934 3.51472L74.8787 12L66.3934 20.4853C65.8076 21.0711 65.8076 22.0208 66.3934 22.6066C66.9792 23.1924 67.9289 23.1924 68.5147 22.6066L78.0607 13.0607ZM0 13.5H77V10.5H0V13.5Z"
                                    fill="currentColor"
                                />
                            </svg>
                        ) : (
                            <svg className="arrow-svg ml-4" width="24" height="9" viewBox="0 0 24 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M23.3536 4.83024C23.5488 4.63498 23.5488 4.31839 23.3536 4.12313L20.1716 0.941151C19.9763 0.745888 19.6597 0.745888 19.4645 0.941151C19.2692 1.13641 19.2692 1.453 19.4645 1.64826L22.2929 4.47668L19.4645 7.30511C19.2692 7.50037 19.2692 7.81696 19.4645 8.01222C19.6597 8.20748 19.9763 8.20748 20.1716 8.01222L23.3536 4.83024ZM0 4.97668H23V3.97668H0V4.97668Z"
                                    fill="currentColor"
                                />
                            </svg>
                        )}
                    </Link>
                    <style jsx>{`
                        .arrow-svg {
                            @apply cursor-pointer;
                        }
                        .translate-arrow-on-hover > .arrow-svg {
                            @apply duration-500 ease-in-out;
                        }
                        .translate-arrow-on-hover:hover > .arrow-svg {
                            @apply translate-x-4 transform;
                        }
                    `}</style>
                </>
            );
        case 'button': {
            const baseProps = {
                height: 'auto',
                width: 'auto',
                color: bgColor ? 'transparent' : 'primary',
                className: cx([className ? className : 'items-center justify-center', darkStyles && 'dark:bg-white dark:text-black', buttonHoverClasses, 'hover:no-underline']),
                style: {
                    ...textStyle,
                    ...(bgColor && { backgroundColor: bgColor })
                }
            };
            if (isModal) {
                return (
                    <>
                        <Button as="a" onClick={() => setModalIsOpen(true)} {...baseProps} variant="contained">
                            <Text />
                        </Button>
                        {modal}
                    </>
                );
            }
            if (href) {
                return (
                    <Link legacyBehavior href={href} passHref scroll={noLinkHash(href)}>
                        <Button as="a" variant="contained" {...baseProps} {...(linkTarget && { target: linkTarget })}>
                            <Text />
                        </Button>
                    </Link>
                );
            }
            return null;
        }
        case 'text': {
            if (isModal) {
                return (
                    <>
                        <button className={className} onClick={() => setModalIsOpen(true)} style={{ ...textStyle }}>
                            <Text />
                        </button>
                        {modal}
                    </>
                );
            }
            if (href) {
                return (
                    <Link
                        className={cx(['hover:underline', className])}
                        {...(linkTarget && { target: linkTarget })}
                        style={{ ...textStyle }}
                        href={href}
                        passHref
                        scroll={noLinkHash(href)}>
                        <Text />
                    </Link>
                );
            }
            return null;
        }
        default:
            return null;
    }
};

export default SubCTA;

'use client';
import Script from 'next/script';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import MinifiedFunraiseScript from './MinifiedFunraiseScript';

const injectScript = (formId, allocationId, redirect) => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
    script.id = 'funraise-redirect';
    script2.id = 'funraise-push';

    script.innerHTML = `
        function giveRedirect(donor, donation) {
            if(donation.paymentType !== 'bitcoin') {
                window.location.href = '${redirect || 'https://jewsforjesus.org/thank-you'}';
            }
        }
    `;

    script2.innerHTML = `
    if (window.funraise) {
      window?.funraise?.push('create', { form: ${formId} }, {
        selector: '#fr-placed-form-container-${formId}',
        type: 'grow_contained',
      });
      window?.funraise?.push('config', { form: ${formId} }, {
        defaultValues: { allocation: ${allocationId} },
      });
      window?.funraise?.push('config', { form: ${formId} }, {
            i18n: {
                phrases: {
                    donor_information_email_opt_in: {
                        'en': 'Send me newsletter and partnering emails!',
                    }
                }
            }
        }) 
      ${redirect !== 'None' ? `window?.funraise?.push('onSuccess', { form: ${formId} }, giveRedirect)` : ''}
    }
  `;

    document.body.appendChild(script);
    document.body.appendChild(script2);
};

export default function PlacedFormScript({ formId, allocationId, redirect }) {
    const router = useRouter();

    useEffect(() => {
        // const handleBeforeUnload = event => {
        //     // Custom logic before the window unloads
        //     event.preventDefault();
        //     event.returnValue = ''; // Chrome requires returnValue to be set

        //     window.funraise.push('remove', { form: formId });
        // };

        // window.addEventListener('beforeunload', handleBeforeUnload);

        // const injectFunraiseScript = () => {
        //     const script = document.createElement('script');
        //     script.id = 'funraise';

        //     script.innerHTML = `(function(f,u,n,r,a,i,s,e){var data={window:window,document:document,tag:"script",data:"funraise",orgId:f,uri:u,common:n,client:r,script:a};var scripts;var funraiseScript;data.window[data.data]=data.window[data.data]||[];if(data.window[data.data].scriptIsLoading||data.window[data.data].scriptIsLoaded)return;data.window[data.data].loading=true;data.window[data.data].push("init",data);scripts=data.document.getElementsByTagName(data.tag)[0];funraiseScript=data.document.createElement(data.tag);funraiseScript.async=true;funraiseScript.src=data.uri+data.common+data.script+"?orgId="+data.orgId;scripts.parentNode.insertBefore(funraiseScript,scripts)})('e5638293-bcca-4745-9574-d09856c24860','https://assets.funraise.io','/widget/common/2.0','/widget/client','/inject-form.js')`;

        //     document?.body?.appendChild(script);
        // };

        // Function to inject the Funraise script

        // Ensure the Funraise script is loaded before running the custom script
        const loadFunraiseScript = (formId, allocationId, redirect) => {
            if (window?.funraise) {
                injectScript(formId, allocationId, redirect);
                console.log('Funraise script already loaded', formId, allocationId);
            } else {
                console.log('not loaded');
                // injectFunraiseScript();
                // const script = document.createElement('script');
                // script.src = 'https://assets.funraise.io/widget/common/2.0/inject-form.js';
                // script.async = true;
                // script.onload = injectScript; // Inject the script once the Funraise script is loaded
                // document.body.appendChild(script);
            }
        };

        loadFunraiseScript(formId, allocationId, redirect);

        const iframe = document.getElementById(`fr-placed-form-container-${formId}`);
        if (iframe) {
            iframe?.setAttribute('scrolling', 'no');
        }

        // Cleanup function to remove the script on unmount
        return () => {
            window.funraise.push('remove', { form: formId });
            // const existingScript = document.getElementById('funraise-push');
            // if (existingScript) {
            //     document.body.removeChild(existingScript);
            // }
        };
    }, []);

    useEffect(() => {
        const handleRouteChangeStart = url => {
            console.log(`Starting to navigate to ${url}`);
            // window?.funraise?.push('remove', { form: formId });
        };

        const handleRouteChangeComplete = url => {
            console.log(`Completed navigation to ${url}`);
        };

        // Attach the event listeners
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        // router.events.on('hashChangeComplete', handleRouteChangeComplete);

        // Cleanup the event listeners on unmount
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events]);

    return null;
}

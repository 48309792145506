export const getMediaResourceProps = (subMedia: any) => {
    return {
        type: subMedia?.mediaType,
        ...(subMedia?.image?.sourceUrl && {
            image: subMedia?.image,
            imageSize: subMedia?.imageSize
        }),
        ...(subMedia?.mobileImage?.sourceUrl && {
            mobileImage: subMedia?.mobileImage,
            mobileImageSize: subMedia?.mobileImageSize
        }),
        ...(subMedia?.videoLink && {
            videoUrl: subMedia?.videoLink
        }),
        ...(subMedia?.makeImageRound && {
            makeImageRound: subMedia?.makeImageRound
        }),
        ...(subMedia?.imagePriorityLoading && {
            imagePriorityLoading: subMedia?.imagePriorityLoading
        })
    };
};

// import * as React from 'react';
import cx from 'classnames';
// import dynamic from 'next/dynamic';
import Image from 'next/image';
// import { Heading } from '../WYSIWYG';
import { contentAlignment } from '../../utils/contentAlignment';
import Background from '../Background';
import TextContainer from '../Background/TextContainer';
import Container from '../Container';
import SubCTA from '../SubCTA';
import LinkContainer from '../SubCTA/LinkContainer';
import SpacingLayout from '../SpacingLayout';
import SubHeader from '../SubHeader';

const CallToAction = (props: any) => {
    const { subHeader, subcta, subLayout, headerDisplay, image, subBackground } = props;
    const { backgroundImage, textContainerClassesCssSafelist } = subBackground;
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
    // const showAsArrow = subcta?.ctaDisplay === 'arrow';
    // const titleAsBold = headerDisplay !== 'inline' || showAsArrow;
    const hasCta = callToAction?.length > 0;
    const headerAlignmentClass = contentAlignment(subHeader?.headerAlignment);

    function CTA() {
        if (hasCta) {
            return (
                <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                    {callToAction?.map((singleCta, index) => {
                        return (
                            <SubCTA
                                key={index}
                                type={singleCta?.ctaDisplay}
                                href={singleCta?.ctaLink?.url}
                                target={singleCta?.ctaTarget}
                                linkTarget={singleCta?.ctaLink?.target}
                                text={singleCta?.ctaLink?.title}
                                bgColor={singleCta?.ctaBgColor}
                                arrowSize="large"
                                textColor={singleCta?.ctaTextColor || 'white'}
                                className={singleCta?.ctaCssClassesCssSafelist}
                                modalContents={singleCta?.modalContents}
                                darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== 'yellow')}
                            />
                        );
                    })}
                </LinkContainer>
            );
        }
        return null;
    }
    return (
        <Background {...subBackground}>
            <Container width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
                <SpacingLayout contentWidth={subLayout?.contentWidth} defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist}>
                    <div className={cx('flex px-4', headerDisplay === 'inline' ? 'flex-col lg:flex-row' : 'flex-col', headerAlignmentClass)}>
                        {image?.sourceUrl && (
                            <Image
                                src={image.sourceUrl}
                                alt={image.altText || 'Call to action Logo'}
                                className={cx(headerDisplay === 'block' && 'mb-4')}
                                height={100}
                                width={100}
                            />
                        )}
                        <TextContainer classes={textContainerClassesCssSafelist} bgImage={backgroundImage}>
                            <SubHeader {...subHeader} />
                            <CTA />
                        </TextContainer>
                    </div>
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default CallToAction;

import * as React from 'react';
import Link from 'next/link';
import cx from 'classnames';
import css from 'styled-jsx/css';
import noLinkHash from '@/utils/noLinkHash';

export type HeadingProps = {
    as?: any;
    color?: 'lightBlue' | 'darkBlue' | 'yellow' | 'black' | 'white' | 'inherit' | 'merigold';
    size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
    fontWeight?: 'inherit' | 'normal' | 'bold';
    fontFamily?: 'proxima' | 'escrow' | 'roboto';
    html?: string | null | number;
    textDecoration?: 'inherit' | 'default';
    headerLink?: any;
    style?: any;
} & Omit<React.HTMLProps<HTMLParagraphElement>, 'ref' | 'as' | 'size'>;

const styles = css`
    a.td-default.c-heading {
        text-decoration: none;
    }

    a.td-default.c-heading:hover {
        text-decoration: underline;
    }

    .c-heading :global(.column-2 ul),
    .c-heading :global(ul.column-2) {
        columns: 2;
    }

    .c-heading :global(.column-3 ul),
    .c-heading :global(ul.column-3) {
        columns: 3;
    }

    .c-heading :global(.column-4 ul),
    .c-heading :global(ul.column-4) {
        columns: 4;
    }

    .c-heading :global(ol),
    .c-heading :global(ul) {
        @apply ml-5;
        list-style: unset;
        padding: unset;
    }

    .c-heading :global(pre) {
        white-space: pre-wrap;
        background: #f8f8f8;
        padding: 1em;
        margin: 1em 0;
    }

    .c-heading :global(ol) {
        list-style-type: decimal;
    }

    .c-heading :global(h1),
    .c-heading :global(h2),
    .c-heading :global(h3),
    .c-heading :global(h4),
    .c-heading :global(h5),
    .c-heading :global(h6),
    .c-heading :global(img) {
        @apply mb-2.5 leading-tight;
    }

    .c-heading :global(h1),
    .c-heading :global(h2),
    .c-heading :global(h3),
    .c-heading :global(h4),
    .c-heading :global(h5),
    .c-heading :global(h6) {
    }

    .c-heading :global(p),
    .c-heading :global(ol),
    .c-heading :global(ul) {
    }
    .c-heading :global(p:not(:only-child)),
    .c-heading :global(ol:not(:only-child)),
    .c-heading :global(ul:not(:only-child)) {
        margin-bottom: 1.4rem;
    }
    .c-heading :global(p),
    .c-heading :global(ol),
    .c-heading :global(ul) {
    }
    .c-heading :global(ul) {
        list-style-type: disc;
    }
    .c-heading :global(ol) {
        list-style-type: decimal;
    }
    .c-heading :global(.alignleft) {
        @apply mr-auto;
    }
    .c-heading :global(.alignright) {
        @apply ml-auto;
    }
    .c-heading :global(.aligncenter) {
        @apply mx-auto;
    }
`;

const Heading = React.forwardRef<React.ReactNode, HeadingProps>(
    ({ as, color, className, fontFamily, fontWeight = 'normal', textDecoration = 'default', headerLink, html, size, id, ...rest }, ref) => {
        const ElementType = as || 'div';
        const classes = cx(
            className,
            'c-heading',
            textDecoration === 'default' && 'td-default',
            size && `heading-${size}`,
            color && {
                'text-lightBlue': color === 'lightBlue',
                'text-darkBlue': color === 'darkBlue',
                'text-black': color === 'black',
                'text-white': color === 'white',
                'text-current': color === 'inherit',
                'color-merigold': color === 'merigold'
            },
            {
                'font-proxima': fontFamily === 'proxima',
                'font-escrow': fontFamily === 'escrow'
            }
        );

        const __html = typeof html === 'string' ? html?.replace(/\n{2,}/g, '<br />').replace(/\n/g, '') : html;

        const fontBold = fontWeight === 'bold' ? 'bold' : 'normal';

        return (
            <>
                {headerLink ? (
                    <>
                        <Link title={headerLink?.title} hrefLang="en" href={headerLink?.url} scroll={noLinkHash(headerLink?.url)}>
                            <ElementType
                                id={id}
                                ref={ref}
                                className={classes}
                                // style={style}
                                {...rest}
                                dangerouslySetInnerHTML={{
                                    __html
                                }}
                                style={{ lineHeight: '1.375', fontWeight: fontBold }}
                            />
                        </Link>
                        <style jsx>{styles}</style>
                    </>
                ) : (
                    <>
                        <ElementType
                            id={id}
                            ref={ref}
                            className={classes}
                            {...rest}
                            dangerouslySetInnerHTML={{
                                __html
                            }}
                        />
                        <style jsx>{styles}</style>
                    </>
                )}
            </>
        );
    }
);
Heading.displayName = 'Heading';
export default Heading;

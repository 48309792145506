'use client';
import { useEffect, useState, useId } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import PageModal from '@/components/PageModal';
import Script from 'next/script';
import cx from 'classnames';
// import MinifiedFunraiseScript from '../components/DonationScripts/MinifiedFunraiseScript';
import PlacedFormScript from '@/components/DonationScripts/PlacedFormScript';

export default function IDonateForm({ campaignId, designationId, embedId, allocationId, redirect, formId, idonate, donationModule }) {
    const router = useRouter();
    const [safariBrowser, setIsSafariBrowser] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const asPath = router?.asPath;
    const params = asPath.substring(asPath.indexOf('?'));
    const { designation, reference_code } = router?.query;
    const noDesIdPages = asPath.includes('give-hineni') || asPath.includes('the-upper-room');
    const desId = !noDesIdPages ? (designation || designationId || 'd4c15680-8d55-4d98-b538-1aa1e1e57ef2').trim() : '';
    const campId = campaignId?.trim() || '9f092f15-ba22-4fdf-90c7-996c4b97f9d4';
    const refCode = reference_code || 'STNETDON';
    // const embedId = 'ea55e7ce-0639-4f97-926d-89e55b85982c';
    const embedIdAttributes = embedId
        ? {
              id: 'idonate-giving-form-container',
              'data-embed-id': embedId
          }
        : '';

    const paramsReplace = params.replace(/=/g, ':').replace(/&/g, ', data-').replace('?', 'data-');
    const paramsSplit = paramsReplace.split(/[:|,]/);

    let extraAttributes;
    for (let i = 0; i < paramsSplit.length; i += 2) {
        extraAttributes = {
            [paramsSplit[i].trim()]: paramsSplit[i + 1],
            ...extraAttributes
        };
    }

    const modalContents =
        '<h3>Donation Account Login</h3><p>To make a donation that is marked to an existing donation account, simply use your account email address in the donation form. <strong>Login is not required.</strong></p><p>If you need to make an update to your existing account, login to the <a href="https://donor.idonate.com/sandbox-org">Secure Donation Update</a> portal.</p> <p className="mt-8"><a className="btn-blue" href="https://donor.idonate.com/sandbox-org">Log In</a></p>';

    // return (
    //     <IFrame
    //         src={`${process.env.NEXT_PUBLIC_WP}/give?designation=${desId}&campaign=${campId}&embed_id=${embedId}&reference_code=${refCode}${params ? '&' + params : ''}`}
    //         width="100%"
    //         className={cx([
    //             className,
    //             keepDefaultHeight && 'min-h-[1650px] xsm:min-h-[1550px] sm:min-h-[1325px]',
    //             staffPage && 'md:min-h-[1400px] lg:min-h-[1300px] 2xl:min-h-[1200px]'
    //         ])}
    //     />
    // );

    useEffect(() => {
        // remove existing script for idonate
        (navigator.userAgent.includes('Safari') || navigator.userAgent.includes('Firefox')) && setIsSafariBrowser(true);
        const iDonateSite = embedId ? 'https://apps.idonate.com/idonate-giving-form.js' : 'https://embed.idonate.com/idonate.js';
        const scripts = document.querySelectorAll('script');
        scripts.forEach(script => {
            if (script.src.includes(iDonateSite) && !script.dataset.type === 'goalMeter') {
                document.body.removeChild(script);
            }
        });
        // window?.idonatelib?.reset?.();
        const script = document.createElement('script');
        script.src = iDonateSite;
        script.async = true;
        document.body.appendChild(script);
        window?.initializeEmbeds?.();
        return () => {
            // document.body.removeChild(script);
        };
    }, []);

    // console.log('d', donationModule);

    return (
        <div>
            {/* <Script src={embedId ? 'https://apps.idonate.com/idonate-giving-form.js' : 'https://embed.idonate.com/idonate.js'} /> */}
            {/* <a href="/thank-you/donor-login" target="_blank" className="ml-auto block w-[50px] pb-2 pr-1 text-right text-sm font-semibold text-softGray underline">
                Login
            </a> */}
            {allocationId || !idonate ? (
                <>
                    <PlacedFormScript formId={formId} allocationId={allocationId} redirect={redirect} />
                    <div
                        id={`fr-placed-form-container-${formId}`}
                        // style={{ minHeight: '816px' }}
                        className={cx('mx-auto flex min-h-[950px] justify-center', donationModule && 'donation-module')}></div>
                </>
            ) : (
                <>
                    {(router?.query?.uri?.length > 0 || safariBrowser) && (
                        <div className="dark:bg-[#343434]">
                            <div data-idonate-embed={campId} data-designation={desId} data-reference_code={refCode} {...embedIdAttributes} {...extraAttributes}></div>
                        </div>
                    )}
                </>
            )}

            {/* <button aria-label="Donate" type="button" data-formid="38638">
                Click Me
            </button> */}
        </div>
    );
}

import React from 'react';
import { OrderEnum, PostObjectsConnectionOrderbyEnum, usePostsQuery, Maybe } from '../../../generated/graphql';
import FeaturedListGrid from './FeaturedListGrid';

type PostsWrapperProps = {
    count: number;
    by?: Maybe<string>;
    isGrid?: Maybe<boolean>;
    categories?: Maybe<Array<string>>;
    isPriorityImages?: Maybe<boolean>;
    imageOnly?: Maybe<boolean>;
};
const PostsWrapper: React.FC<PostsWrapperProps> = props => {
    const { count, categories, isPriorityImages, imageOnly } = props;

    const { data } = usePostsQuery({
        variables: {
            first: count,
            afterCursor: '',
            beforeCursor: '',
            orderField: PostObjectsConnectionOrderbyEnum.DATE,
            order: OrderEnum.DESC,
            categoryName: categories?.toString()
        }
    });

    const list = data?.posts?.nodes?.map((event: any) => ({
        title: event?.title,
        uri: event?.uri,
        image: event?.featuredImage?.node
    }));
    return <FeaturedListGrid isPriorityImages={isPriorityImages} list={list} imageOnly={imageOnly} />;
};

export default PostsWrapper;

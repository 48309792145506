import React from 'react';

function ModalXIcon({ onClose }) {
    return (
        <svg onClick={onClose} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.6668 2.68398L21.3168 0.333984L12.0002 9.65065L2.6835 0.333984L0.333496 2.68398L9.65016 12.0007L0.333496 21.3173L2.6835 23.6673L12.0002 14.3507L21.3168 23.6673L23.6668 21.3173L14.3502 12.0007L23.6668 2.68398Z"
                fill="#000535"
            />
        </svg>
    );
}

export default ModalXIcon;

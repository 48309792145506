export default function convertCssStringToReactStyle(cssString) {
    if (!cssString) return null;
    const style = {};
    cssString.split(';').forEach(cssProperty => {
        const [property, value] = cssProperty.split(':');
        if (property && value) {
            const prop = property?.replace(/(-\w)/g, match => match[1]?.toUpperCase());
            style[prop?.trim()] = value?.trim();
        }
    });
    return style;
}

import * as React from 'react';
import RModal from 'react-modal';
import { motion } from 'framer-motion';
import cx from 'classnames';
import { components } from './Modal-styles';
import usePrevious from '../../hooks/usePrevious';
import ModalXIcon from 'src/svgs/ModalXIcon';

type ModalProps = {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    id?: string;
};

const bodyAnimation = {
    close: {
        scale: 0.7,
        opacity: 0
    },
    open: {
        scale: 1,
        opacity: 1
    }
};

const Modal: React.FC<ModalProps> = props => {
    const { open, id, children, size, onClose } = props;
    const prevOpen = usePrevious(open);
    const [show, setShow] = React.useState<boolean>(open);
    RModal.setAppElement('body');
    React.useEffect(() => {
        if (open && !show) {
            document.querySelector('body').classList.add('overflow-hidden');
            setShow(true);
        }
    }, [open, show]);

    React.useEffect(() => {
        if (prevOpen && !open) {
            document.querySelector('body').classList.remove('overflow-hidden');
            hide();
        }
    }, [open, prevOpen]);

    function hide() {
        setTimeout(() => {
            setShow(false);
        }, 400);
    }

    return (
        <RModal
            isOpen={show}
            // appElement={document?.getElementById('my-body' || undefined)}
            onRequestClose={onClose}
            overlayClassName={cx('bm-blur z-[60]', components.className)}
            className={cx(
                'bm-container',
                'modal-container',
                {
                    'bm-small': size === 'small',
                    'bm-medium': size === 'medium',
                    'bm-large': size === 'large'
                },
                components.className
            )}>
            <motion.div className={cx('bm-body', components.className)} variants={bodyAnimation} transition={{ duration: 0.3 }} initial="close" animate={open ? 'open' : 'close'}>
                <div className="close-button-container mb-6 cursor-pointer self-end md:mb-0">
                    <ModalXIcon onClose={onClose} />
                </div>

                {children}
            </motion.div>
            {components.styles}
        </RModal>
    );
};

Modal.defaultProps = {
    size: 'medium'
};

export default Modal;

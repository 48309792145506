import css from 'styled-jsx/css';
import cx from 'classnames';

import { SubBackgroundProps } from '../../types/ComponentsProps';

import { AxisXLocation, AxisYLocation, CssResolve } from '../../types/StyleTypes';

import { locationAsPosition } from '@/utils/style';

import lightOrDark, { getLightOrDarkColor } from '@/utils/lightOrDark';

export function getBackgroundInlineStyle(props: SubBackgroundProps) {
    const { backgroundType } = props;

    switch (backgroundType) {
        case 'image': {
            return {
                ...getImageInlineStyle(props),
                color: props.textColor ? props.textColor : getLightOrDarkColor(props.backgroundColor)
            };
        }
        case 'circles':
        case 'color': {
            return {
                backgroundColor: props.backgroundColor ? props.backgroundColor : null,
                color: props.textColor ? props.textColor : getLightOrDarkColor(props.backgroundColor)
            };
        }
        case 'gradient': {
            return {
                background: `linear-gradient(79.45deg, ${props?.startColor} 0%, ${props?.endColor} 100%)`,
                color: props.textColor ? props.textColor : getLightOrDarkColor(props.backgroundColor)
            };
        }
        default:
            return {};
    }
}

// Dynamic scoped styles

export function getDynamicStyles(props: SubBackgroundProps) {
    return props?.backgroundType === 'image' && props.backgroundScale === 'custom' ? imageScaleToBgSize(props) : null;
}

export function getClassNames(bgProps: SubBackgroundProps, classNames: (string | undefined)[] = []): string {
    return cx(
        [...classNames],
        'w-full',
        bgProps?.backgroundType === 'circles' && [
            'bg-circles relative overflow-hidden',
            {
                'vertical-bottom': bgProps.backgroundVerticalLocation === 'bottom',
                'vertical-top': bgProps.backgroundVerticalLocation === 'top',
                'vertical-center': bgProps.backgroundVerticalLocation === 'center',
                'horizontal-left': bgProps.backgroundHorizontalLocation === 'left',
                'horizontal-right': bgProps.backgroundHorizontalLocation === 'right'
            }
        ],
        {
            'scale-to-bg-size': bgProps?.backgroundType === 'image' && bgProps.backgroundScale === 'custom'
        }
    );
}

// Helper functions

function imageScaleToBgSize({ mobileScale, tabletScale, desktopScale }: SubBackgroundProps) {
    return css.resolve`
        .scale-to-bg-size {
            background-size: ${mobileScale || 100};
        }

        @screen md {
            .scale-to-bg-size {
                background-size: ${tabletScale || 100}%;
            }
        }

        @screen lg {
            .scale-to-bg-size {
                background-size: ${desktopScale || 100}%;
            }
        }
    `;
}

function getImageInlineStyle(props: SubBackgroundProps) {
    const { backgroundVerticalLocation, backgroundHorizontalLocation } = props;
    if (props.backgroundImage?.sourceUrl) {
        const baseStyle = imageBaseStyle(props);

        switch (props.backgroundScale) {
            case 'cover':
                return {
                    ...baseStyle,
                    backgroundPosition: `${backgroundVerticalLocation} ${backgroundHorizontalLocation}`,
                    backgroundSize: 'cover'
                };
            case 'contain':
                return {
                    ...baseStyle,
                    backgroundSize: 'contain'
                };
            case 'custom':
                return baseStyle;
            case 'none':
                return baseStyle;
            default:
                return {};
        }
    }
}

export function backgroundOverlay(props: SubBackgroundProps) {
    const { overlayOptions, overlayColor, overlayOpacity } = props;
    const hasOverlay = overlayOptions === 'solid';
    return {
        ...(hasOverlay && {
            backgroundBlendMode: 'overlay',
            backgroundColor: overlayHexToBgColor(overlayColor, overlayOpacity)
        })
    };
}

function imageBaseStyle(props: SubBackgroundProps) {
    const { backgroundImage, overlayOptions, overlayColor, overlayOpacity, backgroundColor, backgroundVerticalLocation, backgroundHorizontalLocation } = props;
    const sourceUrl = backgroundImage?.sourceUrl;
    const hasOverlay = overlayOptions === 'solid';
    let backgroundPosition = '';

    if (sourceUrl) {
        if (backgroundHorizontalLocation && backgroundVerticalLocation) {
            backgroundPosition = locationAsPosition({
                axisX: backgroundHorizontalLocation as AxisXLocation,
                axisY: backgroundVerticalLocation as AxisYLocation
            });
        }
        return {
            backgroundRepeat: 'no-repeat',
            backgroundPosition,
            ...(backgroundColor && {
                backgroundColor
            }),
            ...(hasOverlay && {
                backgroundBlendMode: 'overlay',
                backgroundColor: overlayHexToBgColor(overlayColor, overlayOpacity)
            })
        };
    }
}

export function getVideoProps() {
    return {
        preload: 'auto',
        muted: true,
        playsInline: true,
        autoPlay: true,
        loop: true,
        'webkit-playsinline': 'true'
    };
}

export function overlayHexToBgColor(hex: string, opacity: any) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity}%)` : '';
}

export function getWrapperProps(isVideo: any, bgProps: any, className: any) {
    return isVideo
        ? {
              className: cx('w-full relative overflow-hidden', className),
              ...(bgProps.overlayOptions === 'solid' && {
                  style: {
                      backgroundColor: overlayHexToBgColor(bgProps.overlayColor, bgProps.overlayOpacity)
                  }
              })
          }
        : {};
}

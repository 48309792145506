import cx from 'classnames';

const TextContainer = ({ children, bgImage, classes }) => {
    if (bgImage) {
        return <div className={cx('bg-image-text-container relative z-20', classes)}>{children}</div>;
    }
    return <>{children}</>;
};

export default TextContainer;

import cx from 'classnames';
// import dynamic from 'next/dynamic';

import Background from '@/components/Background';
import Container from '@/components/Container';
import SpacingLayout from '@/components/SpacingLayout';
import SubHeader from '@/components/SubHeader';
import PostsWrapper from './PostsWrapper';
import FeaturedListGrid from './FeaturedListGrid';

// import { getTextAlignment } from '@/utils/text';
// import { Heading } from '@/components/WYSIWYG';

const FeaturedPostsGrid = (props: any) => {
    const { subBackground, subLayout, subHeader, subPost } = props;
    const { postToShow, categoryToShow, selectedPosts, numberOfPostsToDisplay, isPriorityImages, imageOnly } = subPost;

    // const { headerAlignment, mobileHeaderAlignment } = subHeader;

    const shouldShowSelected = postToShow === 'Selected';
    // const textClass = getTextAlignment(subLayout?.contentAlignment);

    const count = numberOfPostsToDisplay || 3;
    const selectedList = selectedPosts?.map((entry: any) => {
        switch (entry?.__typename) {
            case 'Post':
            case 'Page':
                return {
                    title: entry?.title,
                    uri: `${entry?.uri}`,
                    excerpt: entry?.excerpt,
                    subTitle: entry?.acfPosts?.subtitle,
                    image: entry?.featuredImage?.node,
                    headerColor: entry?.acfPosts?.headerColor,
                    pageHeaderSettings: entry?.pageHeaderSettings
                };
            default:
                return {};
        }
    });
    return (
        <Background {...subBackground}>
            <Container width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
                <SpacingLayout featuredPostsGrid defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist}>
                    {(subHeader?.title || subHeader?.subtitle) && (
                        <div className={cx('mb-3 md:mb-3', subLayout?.contentAlignment)}>
                            <SubHeader {...subHeader} />
                        </div>
                    )}
                    {shouldShowSelected && <FeaturedListGrid imageOnly={imageOnly} list={selectedList} />}
                    {!shouldShowSelected && (
                        <PostsWrapper
                            isPriorityImages={isPriorityImages}
                            count={count}
                            by={postToShow}
                            categories={categoryToShow?.map((category: any) => category?.slug as string)}
                            imageOnly={imageOnly}
                        />
                    )}
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default FeaturedPostsGrid;

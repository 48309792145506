import cx from 'classnames';

type SpacingLayoutProps = {
    className?: string;
    changePaddingOnMobile?: boolean | null;
    contentWidth?: string | null;
    boxShadow?: string | null;
    contentAlignment?: string | null;
    children?: any;
    fourUpSlider?: boolean;
    featuredPostsGrid?: boolean;
    featuredPosts?: boolean;
    defaultSpacing?: boolean;
};
const SpacingLayout: React.FC<SpacingLayoutProps> = props => {
    const { children, className, contentWidth, contentAlignment, fourUpSlider, featuredPostsGrid, featuredPosts, defaultSpacing } = props;

    // const columnBreakpoint = breakpoint || 'lg';

    return (
        <div
            className={cx(
                'spacing-layout md:mx-auto md:px-0',
                className,
                contentAlignment,
                contentWidth !== 'full' && !fourUpSlider && !featuredPostsGrid && !featuredPosts && 'mx-4 px-3',
                defaultSpacing && 'mt-[4.5rem]'
            )}>
            {children}
        </div>
    );
};

export default SpacingLayout;

// eslint-disable-next-line @typescript-eslint/no-var-requires
import { useId } from 'react';
import parse from 'html-react-parser';
import ReusableSectionLoader from '@/components/ReusableSection';
import FormLoader from '@/components/Modules/Forms/FormLoader';
import NodeToImage from '@/utils/NodeToImage';

export default function ContentReplace({ content }) {
    const index = useId();

    if (!content) return null;
    const reactElement = parse(content);
    if (!reactElement) return content;

    return parse(content, {
        replace: domNode => {
            if (domNode?.data?.includes('[shortcode')) {
                let match;
                const regex = /\[shortcode\stype=([a-z]{1,})\sid=([\d]{1,})(\sstyle=([a-z]{1,}))?(\sfield_values=([“|"|”])(.*?)([”|"]))?(\saltId=([\d]{1,}))?]/gm;
                if ((match = regex.exec(domNode?.data)) !== null) {
                    const reusableModule = match[1];
                    const id = match[2];
                    const style = match[4];
                    const altId = match[10];
                    switch (reusableModule) {
                        case 'form':
                            return <FormLoader key={`form_${index}`} id={+id} match={match} />;
                        case 'reusable':
                            return <ReusableSectionLoader key={`reusable_${index}`} id={id} altId={altId} style={style} />;
                        default:
                            return <div key={`key_${index}`}>Unregistered modules - {JSON.stringify(children)}</div>;
                    }
                }
            }

            if (domNode.name === 'img') {
                return <NodeToImage domNode={domNode} />;
            } else if (domNode.name === 'picture') {
                const { children } = domNode || {};
                return <NodeToImage domNode={children?.slice(-1)[0]} />;
            }
        }
    });
}

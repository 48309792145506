import * as React from 'react';
import cx from 'classnames';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';

type MediaProps = {
    type?: string | null | undefined;
    videoUrl?: string;
    className?: string;
    image?: {
        [key: string]: string | null | undefined;
        sourceUrl?: string | null;
        altText?: string | null;
    };
    makeImageRound?: boolean;
    imagePriorityLoading?: boolean;
    imageSize?: string;
    mobileImageSize?: string;
    mobileImage?: {
        [key: string]: string | null | undefined;
        sourceUrl?: string | null;
        altText?: string | null;
    };
};

const Media: React.FC<MediaProps> = props => {
    const { type, image, className, videoUrl, mobileImage, imageSize, mobileImageSize, makeImageRound, imagePriorityLoading } = props;
    const desktopImageSizes = imageSize?.split('x') || [300, 300];
    const mobileImageSizes = mobileImageSize?.split('x') || [300, 300];

    if (type === 'Image' && image?.sourceUrl) {
        return (
            <>
                <Image
                    src={image?.sourceUrl || ''}
                    alt={image?.altText || ''}
                    width={+desktopImageSizes[0]}
                    height={+desktopImageSizes[1]}
                    className={cx(mobileImage?.sourceUrl && 'hidden md:block', className, makeImageRound && 'rounded-full', 'desktop-image')}
                    priority={imagePriorityLoading}
                />
                {mobileImage?.sourceUrl && isMobile && (
                    <Image
                        priority={imagePriorityLoading}
                        src={mobileImage?.sourceUrl || ''}
                        alt={mobileImage?.altText || ''}
                        width={+mobileImageSizes[0]}
                        height={+mobileImageSizes[1]}
                        className={cx('block md:hidden', className, makeImageRound && 'rounded-full', 'mobile-image')}
                    />
                )}
            </>
        );
    } else if (type === 'Image' && mobileImage?.sourceUrl) {
        return (
            <Image
                src={mobileImage?.sourceUrl || ''}
                alt={mobileImage?.altText || ''}
                priority={imagePriorityLoading}
                width={+mobileImageSizes[0]}
                height={+mobileImageSizes[1]}
                className={cx('block md:hidden', className, makeImageRound && 'rounded-full', 'mobile-image')}
            />
        );
    } else if (type === 'Video' && videoUrl) {
        return (
            <div className={cx('media-video-container relative h-0 w-full bg-white pb-[56.25%]', className)}>
                <iframe title="Video" src={videoUrl} className="media-video absolute left-0 top-0 h-full w-full" />
            </div>
        );
    }
    return null;
};

export default Media;

// import dynamic from 'next/dynamic';
import cx from 'classnames';
import Link from 'next/link';
import { geoData } from '@/utils/geoData';
import noLinkHash from '@/utils/noLinkHash';
// const Bible = dynamic(() => import('@/components/Bible'));
import ContentReplace from '@/utils/contentReplace';
import Background from '@/components/Background';
import TextContainer from '@/components/Background/TextContainer';
import Container from '@/components/Container';
// import Heading from '@/components/WYSIWYG/Heading'
import SpacingLayout from '@/components/SpacingLayout';
import SubCTA from '@/components/SubCTA';
import LinkContainer from '@/components/SubCTA/LinkContainer';
import Media from '@/components/Media';
import SubHeader from '@/components/SubHeader';
import { getMediaResourceProps } from '@/utils/media';

const Content = props => {
    const { subHeader, subBackground, subContent, contentCssClassesCssSafelist, subLayout, subcta, subMedia } = props;
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
    const { backgroundImage, textContainerClassesCssSafelist } = subBackground;
    const resourceProps = getMediaResourceProps(subMedia);
    const noMedia = resourceProps.type === 'Image' && !resourceProps.image;
    const imageLink = subMedia?.imageLink?.url;
    const geo = geoData(contentCssClassesCssSafelist);

    // This is currently used for leading users who are in these countries and on the US donation page back to their respective country's donation page
    if (geo !== 'CN' && contentCssClassesCssSafelist?.includes('in-canada')) return null;
    if (geo !== 'UK' && contentCssClassesCssSafelist?.includes('in-uk')) return null;

    return (
        <Background {...subBackground}>
            <Container width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
                <SpacingLayout
                    defaultSpacing={subLayout?.defaultSpacing}
                    contentWidth={subLayout?.contentWidth}
                    contentAlignment={subLayout?.contentAlignment}
                    className={`content-container relative z-10 ${contentCssClassesCssSafelist} ${subLayout?.layoutCssSafelist}`}>
                    <TextContainer classes={textContainerClassesCssSafelist} bgImage={backgroundImage}>
                        {!noMedia && (
                            <div className={cx([subMedia?.mediaContainerCssCssSafelist, 'content-media-container'])}>
                                {imageLink ? (
                                    <Link hrefLang="en" href={imageLink} scroll={noLinkHash(imageLink)}>
                                        <Media {...resourceProps} className={cx('mx-auto', subMedia?.makeImageRound && 'rounded-full')} />
                                    </Link>
                                ) : (
                                    <Media {...resourceProps} className={cx('mx-auto', subMedia?.makeImageRound && 'rounded-full')} />
                                )}
                            </div>
                        )}
                        <SubHeader {...subHeader} />

                        {subContent && <ContentReplace content={subContent} />}
                    </TextContainer>

                    {callToAction?.length > 0 && (
                        <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                            {callToAction?.map((singleCta, index) => {
                                return (
                                    <SubCTA
                                        key={index}
                                        type={singleCta?.ctaDisplay}
                                        href={singleCta?.ctaLink?.url}
                                        target={singleCta?.ctaTarget}
                                        linkTarget={singleCta?.ctaLink?.target}
                                        text={singleCta?.ctaLink?.title}
                                        bgColor={singleCta?.ctaBgColor}
                                        arrowSize="large"
                                        textColor={singleCta?.ctaTextColor || 'white'}
                                        className={singleCta?.ctaCssClassesCssSafelist}
                                        modalContents={singleCta?.modalContents}
                                        darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== 'yellow')}
                                    />
                                );
                            })}
                        </LinkContainer>
                    )}
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default Content;

import { usePageModalQuery } from '../generated/graphql';
import { Router } from 'next/router';
import * as React from 'react';
import Loading from './Loading';
import Modal from './Modal';
import Modules from './Modules';
import ContentReplace from '@/utils/contentReplace';

const PageModal = props => {
    const { open, targetUri, onClose, modalContents } = props;
    const { loading, data, error } = usePageModalQuery({
        variables: {
            id: targetUri
        },
        skip: !targetUri
    });

    const modules = data?.contentNode?.acfSections?.sections || [];
    const type = data?.contentNode?.__typename || 'Page';

    React.useEffect(() => {
        Router.events.on('routeChangeComplete', onClose);
        return () => {
            Router.events.off('routeChangeComplete', onClose);
        };
    }, [onClose]);

    return (
        <Modal open={open} onClose={onClose} size="large">
            {modalContents && (
                <div className="content-container">
                    <ContentReplace content={modalContents} />
                </div>
            )}
            {loading ? (
                <Loading />
            ) : (
                !modalContents && modules?.map((section, index) => <Modules key={index} moduleData={section} moduleIndex={`module-page-modal-${index}`} type={type} />)
            )}
        </Modal>
    );
};

export default PageModal;

import cx from 'classnames';
import dynamic from 'next/dynamic';
const Background = dynamic(() => import('@/components/Background'));
const Container = dynamic(() => import('@/components/Container'));
const SpacingLayout = dynamic(() => import('@/components/SpacingLayout'));
const PostsWrapper = dynamic(() => import('./PostsWrapper'));
const FeaturedList = dynamic(() => import('@/components/FeaturedList'));
const SubCTA = dynamic(() => import('@/components/SubCTA'));
const LinkContainer = dynamic(() => import('@/components/SubCTA/LinkContainer'));
const SubHeader = dynamic(() => import('@/components/SubHeader'));

const FeaturedPosts = (props: any) => {
    const { subBackground, subLayout, subHeader, subPost, subcta } = props;
    const { callToAction, linkAlignment, linkContainerCssClassCssSafelist } = subcta;
    const { postToShow, categoryToShow, selectedPosts, numberOfPostsToDisplay, isPriorityImages } = subPost;

    const shouldShowSelected = postToShow === 'Selected';
    const count = numberOfPostsToDisplay || 3;
    const selectedList = selectedPosts?.map((entry: any) => {
        switch (entry?.__typename) {
            case 'Post':
            case 'Page':
                return {
                    title: entry?.title,
                    uri: entry?.uri,
                    excerpt: entry?.excerpt,
                    image: entry?.featuredImage?.node,
                    pageHeaderSettings: entry?.pageHeaderSettings
                };
            default:
                return {};
        }
    });

    return (
        <Background {...subBackground}>
            <Container width={subLayout?.contentWidth} customWidth={subLayout?.customWidth}>
                <SpacingLayout featuredPosts defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist}>
                    {!subHeader?.hideTitles && (subHeader?.title || subHeader?.subtitle) && (
                        <div className={cx('mb-3 md:mb-12', subLayout?.contentAlignment)}>
                            <SubHeader {...subHeader} />
                        </div>
                    )}
                    {shouldShowSelected && <FeaturedList list={selectedList} isPriorityImages={isPriorityImages} />}
                    {!shouldShowSelected && <PostsWrapper count={count} categories={categoryToShow?.map((category: any) => category?.slug as string)} />}
                    {callToAction?.length > 0 && (
                        <LinkContainer direction={linkAlignment} className={linkContainerCssClassCssSafelist}>
                            {callToAction?.map((singleCta, index) => {
                                return (
                                    <SubCTA
                                        key={index}
                                        type={singleCta?.ctaDisplay}
                                        href={singleCta?.ctaLink?.url}
                                        target={singleCta?.ctaTarget}
                                        linkTarget={singleCta?.ctaLink?.target}
                                        text={singleCta?.ctaLink?.title}
                                        bgColor={singleCta?.ctaBgColor}
                                        arrowSize="large"
                                        textColor={singleCta?.ctaTextColor || 'white'}
                                        className={singleCta?.ctaCssClassesCssSafelist}
                                        modalContents={singleCta?.modalContents}
                                        darkStyles={Boolean(!subBackground?.backgroundUrl && singleCta?.ctaBgColor !== 'yellow')}
                                    />
                                );
                            })}
                        </LinkContainer>
                    )}
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default FeaturedPosts;

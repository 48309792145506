export function geoData(classList, givePage = false) {
    let country = null;
    if (classList?.includes('in-canada') || classList?.includes('in-uk') || givePage) {
        if (Intl.DateTimeFormat().resolvedOptions().locale === 'en-CA') {
            country = 'CN';
        } else if (Intl.DateTimeFormat().resolvedOptions().locale === 'en-GB') {
            country = 'UK';
        }
    }

    return country;
}

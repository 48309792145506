import css from 'styled-jsx/css';

export const components = css.resolve`
    .bm-blur {
        @apply fixed inset-0 flex items-center justify-center;
        z-index: 12;
        backdrop-filter: blur(3px);
        background-color: rgba(45, 58, 93, 0.5);
    }

    .bm-body {
        @apply relative flex h-auto flex-col overflow-y-auto bg-white px-6 pb-6 pt-6;
        max-height: 90vh;
    }

    .bm-small {
        width: 400px;
    }

    .bm-medium {
        width: 600px;
    }

    .bm-large {
        width: 800px;
    }

    .bm-container {
        @apply outline-none;
        max-height: 90vh;
        max-width: 90%;
    }

    @screen md {
        .bm-body {
            @apply px-8 pb-12 pt-8;
        }
    }
`;

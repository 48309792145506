import * as React from 'react';
import { PageModule } from './types';
import Background from '../Background';
import Container from '../Container';
import lightOrDark from '../../utils/lightOrDark';
import cx from 'classnames';
// import withSizes, { Sizes } from 'react-sizes';
import { isDesktop } from 'react-device-detect';

// type SpacerAnchorProps = Extract<PageModule, { __typename?: 'Page_Acfsections_Sections_SpacerAnchor' }>;

// export type SpacerAnchorSize = {
//     isDesktop?: boolean | undefined;
// };

// export type SpacerAnchorSizeProps = SpacerAnchorProps & SpacerAnchorSize;

const SpacerAnchor = ({ spacerAndAnchor }) => {
    const { anchorLabel, backgroundVisualType, height, mobileHeight, subBackground } = spacerAndAnchor || {};
    const { backgroundColor, textColor, backgroundType, slantSettings } = subBackground || {};
    // const { topSlantColor, bottomSlantColor } = slantSettings.slant || {};
    const isBgDark = backgroundColor && lightOrDark(backgroundColor);
    return (
        <div className="SpacerAnchor" id={anchorLabel!}>
            {backgroundVisualType === 'background' && (
                <Background className={cx('h-full w-full')} {...subBackground}>
                    {anchorLabel && (
                        <Container className={cx('relative flex h-full w-full items-center justify-center text-center', !textColor && isBgDark && 'text-white')}>
                            {anchorLabel}
                        </Container>
                    )}
                </Background>
            )}

            {/* ${backgroundVisualType === 'slant' &&
                    `background: linear-gradient(to right bottom, ${topSlantColor}, ${topSlantColor} 50%, ${bottomSlantColor} calc(50% + 1px), ${bottomSlantColor} 100%);`} */}
            <style jsx>{`
                .SpacerAnchor {
                    display: inline-block;
                    width: 100%;
                    height: ${isDesktop ? height : mobileHeight}px;
                }
                .SpacerAnchor div {
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
            `}</style>
        </div>
    );
};

// const mapSizesToProps = (sizes: Sizes): SpacerAnchorSize => {
//     return { isDesktop: sizes.width >= 1024 };
// };

export default SpacerAnchor;

import React from 'react';
import ReusableSection from './Modules/ReusableSection';
import { useReusableSectionQuery } from '../generated/graphql';
import useIdByDonationCookie from '@/hooks/useIdByDonationCookie';

export default function ReusableSectionLoader({ id, altId, style }) {
    const reusableSectionId = useIdByDonationCookie(id, altId);
    // console.log('r', reusableSectionId);
    const { loading, error, data } = useReusableSectionQuery({
        variables: { id: +reusableSectionId }
    });
    const Div = 'div';

    if (loading) return <div>Loading</div>;
    if (error) return <div>Error</div>;

    if (data) {
        const { reusableSectionBy } = data;

        if (style === 'full')
            return (
                <Div className="full-width-module">
                    <ReusableSection reusablePostItems={reusableSectionBy} />;
                </Div>
            );
        else return <ReusableSection reusablePostItems={reusableSectionBy} />;
    }
}

import * as React from 'react';
import { Heading } from './WYSIWYG';

const Loading: React.FC = () => (
  <div>
    <Heading html="Loading..." as="h3" />
  </div>
);

export default Loading;

import * as React from 'react';
import cx from 'classnames';
import { Heading } from '../../WYSIWYG';
import Link from 'next/link';
import Image from 'next/image';

export type Maybe<T> = T | null;
type MediaItem = {
    altText: Maybe<string>;
    sourceUrl: Maybe<string>;
};

type FeaturedListProps = {
    list?: Maybe<
        Array<{
            title?: Maybe<string>;
            uri?: Maybe<string>;
            image?: ({ __typename?: 'MediaItem' | undefined } & Pick<MediaItem, 'altText' | 'sourceUrl'>) | null | undefined;
        }>
    >;
    isPriorityImages?: Maybe<boolean>;
    imageOnly?: Maybe<boolean>;
};

type PostItemProps = {
    entry?: Maybe<any>;
    index: Maybe<number>;
    length?: Maybe<number>;
    isPriorityImages?: Maybe<boolean>;
    imageOnly?: Maybe<boolean>;
};
const PostItem: React.FC<PostItemProps> = props => {
    const { entry, index, isPriorityImages, imageOnly } = props;
    const { pageHeaderSettings } = entry;
    const featured = index === 0 || index === 5;
    const image = entry?.image?.sourceUrl || '/images/image-placeholder.png';
    const headline = pageHeaderSettings?.postSettings?.headline;
    // Test commit
    return (
        <Link className={cx(['grid', !featured && !imageOnly && 'grid-cols-8', 'h-full'])} href={entry?.uri} passHref>
            {featured ? (
                <div className={cx(['featured-list-grid-featured-item relative', imageOnly && 'mx-7 md:mx-0'])}>
                    <Image src={image} width="570" height="322" alt={entry?.image?.altText || entry?.title} className={'h-full w-full object-cover'} priority={isPriorityImages} />
                    {!imageOnly && (
                        <div className="featured-list-grid-featured-item-text absolute bottom-0 z-10 flex h-full w-full flex-col justify-end bg-featuredPostGrid px-4 pb-5">
                            <Heading html={entry.title} as="h3" color="white" />
                            <div className="z-10 hidden font-normal leading-normal text-white sm:block" dangerouslySetInnerHTML={{ __html: headline }} />
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div className={cx(['featured-list-grid-normal-item relative', !imageOnly ? 'col-span-3 bsm:col-span-4' : 'mx-7 md:mx-0'])}>
                        <Image
                            src={image}
                            width="276"
                            height="156"
                            alt={entry?.image?.altText || entry?.title}
                            className={'h-full min-h-[150px] w-full object-cover bsm:min-h-0'}
                            priority={isPriorityImages}
                        />
                    </div>
                    {!imageOnly && (
                        <div className="featured-list-grid-normal-item-text col-span-5 bg-neutral-100 p-3 text-black dark:bg-zinc-800 dark:text-neutral-100 bsm:col-span-4 sm:p-4">
                            <Heading html={entry?.title} as="h4" className={cx('leading-tight')} />
                            <div className="z-10 font-normal leading-normal" dangerouslySetInnerHTML={{ __html: headline }} />
                        </div>
                    )}
                </>
            )}
        </Link>
    );
};

const FeaturedListGrid: React.FC<FeaturedListProps> = props => {
    const { list, isPriorityImages, imageOnly } = props;
    const _list = list ? [...list] : [];
    // const featured = _list?.shift();

    return (
        <div className={cx(['featured-list-grid-container grid', imageOnly ? 'lg:grid-cols-3' : 'lg:grid-cols-2'])}>
            {_list?.map((entry, index) => {
                if (!entry.uri) return null;
                return (
                    <div
                        key={index}
                        className={cx([
                            'box featured-list-grid-item relative mb-4',
                            index === 0 && `lg:row-span-2 lg:mr-2 ${imageOnly && 'md:col-span-2 md:row-span-2 md:mr-2'}`,
                            (index === 1 || index === 2) && `mb-4 ${imageOnly ? 'md:col-start-3 md:ml-2' : 'lg:col-span-2 lg:col-start-2 lg:ml-2'} `,
                            (index === 3 || index === 4) && 'order-last lg:col-span-1 lg:mr-2',
                            index === 5 && 'mb-4 lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-3 lg:ml-2'
                        ])}>
                        <PostItem isPriorityImages={isPriorityImages} entry={entry} index={index} length={_list.length} imageOnly={imageOnly} />
                    </div>
                );
            })}
        </div>
    );
};

export default FeaturedListGrid;

// import dynamic from 'next/dynamic';
import GravityForm from './GravityForm';
import cx from 'classnames';
import SpacingLayout from '@/components/SpacingLayout';
import Background from '@/components/Background';
import SubHeader from '@/components/SubHeader';
import Container from '@/components/Container';

export default function FormModule(props) {
    const { subBackground, subLayout, subHeader } = props;

    return (
        <Background {...subBackground}>
            <Container width="narrow">
                <SpacingLayout contentWidth={subLayout?.contentWidth} defaultSpacing={subLayout?.defaultSpacing} className={cx([subLayout?.layoutCssSafelist])}>
                    <SubHeader {...subHeader} />
                    <GravityForm {...props} />
                </SpacingLayout>
            </Container>
        </Background>
    );
}

import * as React from 'react';
import cx from 'classnames';
import { BackgroundProps } from '../../types/ComponentsProps';
// import { styles } from './Background-styles';
import { getBackgroundInlineStyle, getWrapperProps, backgroundOverlay } from './utils';
import Image from 'next/image';
import { isMobile } from 'react-device-detect';

// const AnimatedGradient: React.FC<AnimatedGradientProps> = (props) => {
//     const { children, colors, className } = props;
//     const ref = React.useRef<HTMLCanvasElement>(null);
//     React.useEffect(() => {
//         const _colors = colors?.map((e: { color: any }) => e?.color).filter(Boolean);
//         if (_colors && _colors?.length > 2) {
//             const BGGradient = require('./gradientBg.js');
//             const gradient = new BGGradient();
//             // gradient.initGradient(ref.current, _colors);
//         }
//     }, [colors]);

//     return (
//         <div className={cx('relative', className)}>
//             <div className="relative z-10">{children}</div>
//             <div className="absolute top-0 h-full">
//                 <canvas className="w-full h-full" ref={ref} data-js-darken-top data-transition-in />
//             </div>
//         </div>
//     );
// };

export type BackgroundScale = 'cover' | 'contain' | 'inherit' | 'fill' | 'none' | null;

const Background: React.FC<BackgroundProps> = props => {
    const { children, className, gradientColors, mobileBackgroundImage, bgImageCssClassesCssSafelist, darkModeBackgroundColor, imagePriorityLoading, ...bgProps } = props;
    if (bgProps.backgroundType === 'gradient' && bgProps.gradientType === 'Moving') {
        return (
            <div>Animated background coming soon. {children}</div>
            // <AnimatedGradient colors={gradientColors} className={className}>
            //     {children}
            // </AnimatedGradient>
        );
    } else if (bgProps.backgroundType === 'none') {
        return <>{children}</>;
    }
    const inlineStyle = getBackgroundInlineStyle(bgProps);
    // const dynamicStyle = getDynamicStyles(props);
    const isVideo = bgProps.backgroundType === 'video';
    const noBg = !['video', 'image'].includes(bgProps.backgroundType);
    // const classes = getClassNames(bgProps, [dynamicStyle?.className && !isVideo ? className : '']);
    // const Wrapper = isVideo ? 'div' : React.Fragment;
    // const wrapperProps = getWrapperProps(isVideo, bgProps, className);
    const bgImg = bgProps.backgroundImage?.sourceUrl;
    const bgImgMobile = mobileBackgroundImage?.sourceUrl;
    const isImage = bgProps.backgroundType === 'image';
    const { backgroundScale } = bgProps;
    const { backgroundVerticalLocation, backgroundHorizontalLocation, mobileBackgroundHorizontalLocation, mobileBackgroundVerticalLocation } = bgProps;
    const backgroundOverlayStyle = backgroundOverlay(bgProps);
    const objectFit = `object-${backgroundScale}`;

    return (
        // <Wrapper {...wrapperProps}>
        <div style={inlineStyle} className={cx(['h-full', 'bg-type-div', darkModeBackgroundColor && `dark:bg-[${darkModeBackgroundColor}]`])}>
            {/* relative  px-0 */}
            {isImage && (
                <>
                    {bgImg && (
                        <div className={cx(['bg-img-container relative', mobileBackgroundImage && 'hidden md:block'])}>
                            <div className="relative z-10">{children}</div>
                            <div style={backgroundOverlayStyle} className="bg-overlay absolute left-0 top-0 z-[1] h-full w-full" />
                            <div className={cx([bgImageCssClassesCssSafelist, 'bg-image-classes'])}>
                                <Image
                                    src={bgImg}
                                    alt={bgProps.backgroundImage?.altText}
                                    priority={imagePriorityLoading}
                                    fill
                                    className={cx(['bg-image absolute z-0', objectFit])}
                                    style={{ objectPosition: `${backgroundHorizontalLocation} ${backgroundVerticalLocation}` }}
                                />
                            </div>
                        </div>
                    )}
                    {bgImgMobile && (
                        <div className={cx(['bg-mobile-img-container relative block md:hidden'])}>
                            <div className="relative z-10">{children}</div>
                            <div style={backgroundOverlayStyle} className="bg-mobile-overlay absolute left-0 top-0 z-[1] h-full w-full" />
                            <div className={cx([bgImageCssClassesCssSafelist, 'bg-mobile-image-classes'])}>
                                <Image
                                    src={bgImgMobile}
                                    alt={mobileBackgroundImage?.altText}
                                    priority={imagePriorityLoading && isMobile}
                                    fill
                                    className={cx(['absolute z-0 block md:hidden', objectFit])}
                                    style={{ objectPosition: `${mobileBackgroundHorizontalLocation} ${mobileBackgroundVerticalLocation}` }}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            {isVideo && bgProps?.videoLink && (
                <div className="fh-video-container">
                    Video Background coming soon.
                    {/* <video crossOrigin="anonymous" {...getVideoProps()} src={bgProps?.videoLink} className="fh-video" />
                        <iframe src="//www.youtube.com/watch?v=bo_efYhYU2A" {...getVideoProps()} frameBorder="0" className="fh-video"></iframe> */}
                    {/* <iframe
                            id="sharedVideoIFrame"
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            title="YouTube video player"
                            width="100%"
                            height="100%"
                            src={}
                        ></iframe> */}
                </div>
            )}
            {noBg && children}
        </div>
        // </Wrapper>
    );
};

export default Background;

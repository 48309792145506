import React from 'react';
import Modules from './Modules';
import { Maybe } from '../../generated/graphql';

const ReusableSection: React.FC<{ reusablePostItems?: Maybe<any> }> = props => {
    const { reusablePostItems } = props;
    const sections = reusablePostItems?.acfSections?.sections || [];
    return (
        <>
            {sections?.map((section, index) => (
                <Modules key={index} moduleData={section || []} moduleIndex={`module-reusable-${index}`} type="ReusableSection" />
            ))}
        </>
    );
};

export default ReusableSection;

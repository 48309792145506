/* eslint-disable no-unused-vars */
import * as React from 'react';
import cx from 'classnames';
import { styles } from './Button-styles';

type ButtonBaseProps = {
    as?: any;
    className?: string;
    color?: 'transparent' | 'primary' | 'secondary';
    width?: 'default' | 'fluid' | 'auto';
    height?: 'default' | 'fluid' | 'auto';
    variant?: 'outlined' | 'contained';
    onClick?: (event: React.SyntheticEvent) => void;
    disabled?: boolean;
    children?: React.ReactNode;
    style?: React.CSSProperties;
    href?: string;
    target?: string;
    [key: string]: any;
};

const Button = React.forwardRef((props: ButtonBaseProps, ref) => {
    const { as: ElementType = 'button', color = 'primary', width = 'default', height, variant = 'outlined', className, onClick, disabled, children, style, ...rest } = props;
    const classes = cx(
        'button flex',
        color,
        variant,
        {
            disabled,
            'fluid-width': width === 'fluid',
            'default-width': width === 'default',
            'max-w-max': width === 'auto',
            'fluid-height': height === 'fluid',
            'auto-height': height === 'auto' || (!height && variant === 'outlined')
        },
        className
    );
    function handleClick(e: React.SyntheticEvent) {
        if (disabled) {
            e.preventDefault();
            return;
        }
        if (onClick) {
            onClick(e);
        }
    }

    return (
        <ElementType
            className={classes}
            onClick={handleClick}
            disabled={(disabled && ElementType === 'button') || undefined}
            type={ElementType === 'button' ? 'button' : undefined}
            ref={ref}
            style={style}
            {...rest}>
            {children}
            <style jsx>{styles}</style>
        </ElementType>
    );
});
Button.displayName = 'Button';
export default Button;

import cx from 'classnames';

import Background from '@/components/Background';
import Container from '@/components/Container';
import SpacingLayout from '@/components/SpacingLayout';
import SubHeader from '@/components/SubHeader';
import IDonateForm from '@/components/IDonateForm';

const DonationForm = props => {
    const { subHeader, subBackground, subLayout, formOptions } = props;

    const designationId = formOptions?.designationId || 'd4c15680-8d55-4d98-b538-1aa1e1e57ef2';
    const campaignId = formOptions?.campaignId || '9f092f15-ba22-4fdf-90c7-996c4b97f9d4';
    const boxShadow = formOptions?.boxShadow;
    const allocation = formOptions?.allocationId || '16040';

    return (
        <Background {...subBackground}>
            <Container width="normal">
                <SpacingLayout defaultSpacing={subLayout?.defaultSpacing} className={subLayout?.layoutCssSafelist}>
                    <div className={cx([`donation-form-container mx-auto md:w-10/12 lg:w-8/12`, boxShadow && 'px-4 pb-6 md:shadow-md'])}>
                        <SubHeader {...subHeader} />
                        <div className={formOptions?.idonateClassesCssSafelist}>
                            <IDonateForm
                                donationModule
                                allocationId={allocation}
                                redirect={formOptions?.confirmationRedirectUrl}
                                formId={formOptions?.formId || '38638'}
                                campaignId={campaignId}
                                designationId={designationId}
                                keepDefaultHeight={formOptions?.keepDefaultHeight}
                                embedId={formOptions?.embedId?.trim()}
                            />
                        </div>
                    </div>
                </SpacingLayout>
            </Container>
        </Background>
    );
};

export default DonationForm;

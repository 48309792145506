import { useState, useEffect } from 'react';

const useIdByDonationCookie = (id, altId) => {
    const [finalId, setFinalId] = useState(id);

    useEffect(() => {
        if (altId) {
            const gbCookie = document.cookie
                .split('; ')
                .find(row => row.startsWith('j4jSubGivePageView=true') || row.startsWith('j4jGiftConversionThankyou=true') || row.startsWith('j4jNewsletterView=true'));
            if (gbCookie) {
                setFinalId(altId);
            }
        }
    }, []);
    return finalId;
};

export default useIdByDonationCookie;

import Image from 'next/image';
import cx from 'classnames';
import convertCssStringToReactStyle from '@/utils/convertCssStringToReactStyle';

export default function NodeToImage({ domNode }) {
    const { name, attribs } = domNode;
    const { src, alt, class: className, width, height, style, priority } = attribs;

    if (name === 'img' || name === 'picture') {
        return (
            <Image
                src={src}
                alt={alt || 'Jews for Jesus'}
                style={convertCssStringToReactStyle(style)}
                className={cx(['object-contain', className])}
                width={width || 800}
                height={height || 800}
                priority={Boolean(priority) || false}
            />
        );
    }
    return null;
}

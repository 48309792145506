import dynamic from 'next/dynamic';
import cx from 'classnames';

import CallToAction from './CallToAction';
import FeaturedPosts from './FeaturedPosts';
import FeaturedPostsGrid from './FeaturedPostsGrid';
import ColumnsContent from './ColumnsContent';
// import Instafeed from './InstaFeed';
// import Slider from './Slider';
import Content from './Content';
import DonationForm from './DonationForm';
// import FourUpSlider from './FourUpSlider';
import FullwidthSlider from './FullwidthSlider';
import ReusableSection from './ReusableSection';
import SpacerAnchor from './SpacerAnchor';
import FormModule from './Forms/FormModule';

const Slider = dynamic(() => import('./Slider'));
const FourUpSlider = dynamic(() => import('./FourUpSlider'));
const Instafeed = dynamic(() => import('./InstaFeed'), { ssr: false });

function Module({ moduleData, type, moduleIndex }) {
    // Choosing module components based on module field's group name ex: Page_Pagemodules_Modules_Hero
    switch (moduleData.__typename) {
        case `${type}_Acfsections_Sections_CallToAction`:
            return <CallToAction {...moduleData} />;
        case `${type}_Acfsections_Sections_FeaturedPosts`:
            return <FeaturedPosts {...moduleData} />;
        case `${type}_Acfsections_Sections_ColumnsContent`:
            return <ColumnsContent moduleIndex={moduleIndex} {...moduleData} />;
        case `${type}_Acfsections_Sections_FeaturedPostsGrid`:
            return <FeaturedPostsGrid {...moduleData} />;
        case `${type}_Acfsections_Sections_InstagramFeed`:
            return <Instafeed {...moduleData} />;
        case `${type}_Acfsections_Sections_Slider`:
            return <Slider {...moduleData} />;
        case `${type}_Acfsections_Sections_Content`:
            return <Content moduleIndex={moduleIndex} {...moduleData} />;
        case `${type}_Acfsections_Sections_DonationForm`:
            return <DonationForm {...moduleData} />;
        case `${type}_Acfsections_Sections_FourUpSlider`:
            return <FourUpSlider {...moduleData} />;
        case `${type}_Acfsections_Sections_FullwidthSlider`:
            return <FullwidthSlider {...moduleData} />;
        case `${type}_Acfsections_Sections_ReusableSections`:
            return <ReusableSection {...moduleData} />;
        case `${type}_Acfsections_Sections_SpacerAnchor`:
            return <SpacerAnchor {...moduleData} />;
        case `${type}_Acfsections_Sections_GravityForm`:
            return <FormModule {...moduleData} />;
        default:
            return (
                <div>
                    <h1>Module Not Found</h1>
                    <pre>{JSON.stringify(moduleData, undefined, 2)}</pre>
                    <hr />
                </div>
            );
    }
}

function ModuleSettings({ moduleData, moduleIndex, children }) {
    const { subHeader } = moduleData;
    return (
        <section id={moduleIndex} className={cx([subHeader?.sectionCssClassCssSafelist, moduleData.__typename, 'module-section'])}>
            {children}
        </section>
    );
}

export default function Modules({ moduleData, type, moduleIndex }) {
    if (!moduleData) return null;

    return (
        <ModuleSettings moduleData={moduleData} moduleIndex={moduleIndex}>
            <Module moduleData={moduleData} type={type} moduleIndex={moduleIndex} />
        </ModuleSettings>
    );
}

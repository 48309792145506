import * as React from 'react';
import cx from 'classnames';
import { Maybe, Scalars } from '../generated/graphql';
import { Heading } from './WYSIWYG';

type SubHeaderProps = {
    className?: string;
    textClassName?: string | null;
    headerAlignment?: string;
    mobileHeaderAlignment?: string;
    headerColor?: Maybe<Scalars['String']>;
    headerSize?: Maybe<Scalars['String']>;
    subtitle?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    hideTitles?: Maybe<Scalars['String'] | Boolean>;
    headerClassCssSafelist?: Maybe<Scalars['String']>;
    headerId?: Maybe<Scalars['String']>;
    headerLink?: any;
};

const SubHeader: React.FC<SubHeaderProps> = props => {
    const { title, subtitle, hideTitles, headerSize, headerColor, className, textClassName, headerClassCssSafelist, mobileHeaderAlignment, headerAlignment, headerId, headerLink } =
        props;
    if ((!subtitle && !title) || hideTitles) return null;
    return (
        <div className={cx([className, `text-${mobileHeaderAlignment} md:text-${headerAlignment}`])}>
            {title && (
                <Heading
                    html={title}
                    as={headerSize}
                    fontWeight="bold"
                    className={cx('title', textClassName, headerClassCssSafelist)}
                    {...(headerColor && {
                        style: { color: headerColor }
                    })}
                    id={headerId}
                    headerLink={headerLink}
                />
            )}
            {subtitle && (
                <Heading
                    html={subtitle}
                    as="div"
                    {...(headerColor && {
                        style: { color: headerColor }
                    })}
                    className={cx(textClassName, title && 'mt-3 lg:mt-4', 'subtitle')}
                />
            )}
        </div>
    );
};

export default SubHeader;
